@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;

    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;

    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;

    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;

    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --border: #EBECF0;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;

    --radius: 0.5rem;

    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }

  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;

    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;

    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;

    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;

    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;

    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;

    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;

    --border: #EBECF0;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
  }
}

:root {
  --atlas-primary-70: #fa2727;
  --white: #fff;
  --error: #e74444;

  /* Vobb Primary Colors */
  --vobb-primary-0: #fcfbff;
  --vobb-primary-10: #f2f0fb;
  --vobb-primary-20: #e1dafc;
  --vobb-primary-30: #c3b5f8;
  --vobb-primary-40: #a491f5;
  --vobb-primary-50: #866cf2;
  --vobb-primary-60: #6847ee;
  --vobb-primary-70: #4a22eb;
  --vobb-primary-80: #3913d2;
  --vobb-primary-90: #2f10ae;
  --vobb-primary-100: #250c89;
 
  /* Vobb Secondary Colors */
  --vobb-sec-0: #f5f8ff;
  --vobb-sec-10: #e0f1ff;
  --vobb-sec-20: #c1e3ff;
  --vobb-sec-30: #a2d5ff;
  --vobb-sec-40: #83c7ff;
  --vobb-sec-50: #64b9ff;
  --vobb-sec-60: #46abff;
  --vobb-sec-70: #279dff;
  --vobb-sec-80: #088fff;
  --vobb-sec-90: #007fe8;
  --vobb-sec-100: #006ec9;

  /* Neutrals */
  --neutral-100: #101323;
  --neutral-90: #1d2939;
  --neutral-80: #344054;
  --neutral-70: #475467;
  --neutral-60: #667085;
  --neutral-50: #98a2b3;
  --neutral-40: #d0d5dd;
  --neutral-30: #eaecf0;
  --neutral-20: #f2f4f7;
  --neutral-10: #f9fafb;
  --neutral-0: #fcfcfd;

  /* Success Colors */
  --success-50: #008042;
  --success-40: #069952;
  --success-30: #18b368;
  --success-20: #3dcc87;
  --success-10: #82e6b6;
  --success-0: #e6fff3;

  /* Error Colors */
  --error-50: #800000;
  --error-40: #990000;
  --error-30: #b30000;
  --error-20: #cc0000;
  --error-10: #e62e2e;
  --error-0: #ffe6e6;

  /* Warning Colors */
  --warning-50: #a66300;
  --warning-40: #b86e00;
  --warning-30: #c97900;
  --warning-20: #db8400;
  --warning-10: #eda12f;
  --warning-0: #fff5e6;

  /* Info Colors */
  --info-50: #005180;
  --info-40: #006199;
  --info-30: #0071b3;
  --info-20: #0081cc;
  --info-10: #2ea2e6;
  --info-0: #e6f6ff;
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
    color: var(--neutral-100);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden; /* Hide horizontal scrollbar */
    scroll-behavior: smooth;
    font-size: 14px;
    font-family: "Inter", sans-serif;
    font-weight: 400;
  }
}

*,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  transition: 0.3s;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Work Sans";
}

/* width and height */
::-webkit-scrollbar {
  width: 5px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 5px;
}
::-webkit-scrollbar-track:hover {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--vobb-primary-70);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--vobb-primary-70);
}

button,
input,
textarea,
a {
  font-family: "Inter", sans-serif;
  outline: none !important;
}

input::placeholder {
  color: rgba(193, 199, 208, 1);
}

input[type="text"],
input[type="password"] {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f3f3f3 inset !important;
  box-shadow: 0 0 0 30px #f3f3f3 inset !important;
}
.completedForm {
  svg {
    fill: #d3dbff;
    path:nth-of-type(1) {
      stroke: transparent;
    }
    path:nth-of-type(2) {
      stroke: #3d58d9;
    }
  }
}
.ReactFlagsSelect-module_selectBtn__19wW7 {
  border: none !important;
}
